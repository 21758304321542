import React, {forwardRef} from "react";
import BoardView from "./BoardView"
import Home from "./Home"
import CreateBoard from "./CreateBoard"
import Remote from "./Remote"


// import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams
} from "react-router-dom";

// console.log = () => {}

export default function App() {
  return (
    // <Router>
    //   <div>

    //     <Routes>
    //       <Route exact path="/">
    //         <Home />
    //       </Route>
    //       <Route path="/board/create">
    //         <CreateBoard />
    //       </Route>
    //       <Route path="/board/:id/remote" children={<BoardRemote />} />
    //       <Route path="/board/:id" children={<Board />} />



    //     </Routes>
    //   </div>
    // </Router>
	<Router>
	  <div>
		{/* <nav>
		  <ul>
			<li>
			  <Link to="/">Home</Link>
			</li>
		  </ul>
		</nav> */}

		<Routes>
		  <Route exact path="/" element={<Home />} />
		  <Route path="/board/create" element={<CreateBoard />} />
		  <Route path="/board/:id/remote" element={<BoardRemote />} />
		  <Route path="/board/:id" element={<Board />} />
		</Routes>
	  </div>
	</Router>
  );
}

function Board() {
    let { id } = useParams();
    console.log('boardId:', id);
    return (
        <BoardView boardId={id} />
    )
}

function BoardRemote() {
    let { id } = useParams();
    console.log('boardId:', id);
    return (
        <Remote boardId={id} />
    )
}


if (require.main === module) {
  require("./test-hook.jsx")(module.exports);
}
