import React, { Component } from 'react';
import Api from './Api';
import { v4 as uuidv4 } from 'uuid';
import * as _ from "lodash";
import {
	MDBContainer, MDBRow, MDBCol, MDBBtn,
	MDBCard, MDBCardBody, MDBIcon, MDBBtnGroup,
	MDBDropdown, MDBDropdownItem, MDBDropdownMenu,
	MDBDropdownToggle, MDBTabPane, MDBTabContent,
	MDBNav, MDBNavItem, MDBNavLink
} from 'mdbreact';


class Remote extends Component {
	constructor(props) {
		super(props);
		this.state = {
			commandBoxText: "",
			blocks: [],
			selectedBlock: null,
			activeItem: "1"
		}
		this.api = new Api()
		this.publishCommand = this.publishCommand.bind(this)
		this.getBlocks = this.getBlocks.bind(this)
		this.buildBlockHelpers = this.buildBlockHelpers.bind(this)
		this.buildBaseCommands = this.buildBaseCommands.bind(this)
		this.changeSelectedBlock = this.changeSelectedBlock.bind(this)


	}
	toggle = tab => e => {
		if (this.state.activeItem !== tab) {
			this.setState({
				activeItem: tab
			});
		}
	};
	changeSelectedBlock(event) {
		this.setState({
			selectedBlock: event.target.value
		})
	}
	componentDidMount() {
		this.boardId = this.props.boardId;
		this.getBlocks()
	}

	buildBaseCommands() {
		return (
			<MDBRow>
				<MDBCol md='6' className="mb-2">
					<p className="h6 text-center">Slideshow</p>
					<div className="text-center">
						<MDBBtnGroup size="sm" className="mb-2">

							<MDBBtn gradient="heavy-rain-gradient" className="btn ml-2 mr-2 rounded" onClick={() => this.publishCommand(`start slideshow`)}>

								<MDBIcon size="2x" far icon="play-circle" className="blue-grey-text" />
							</MDBBtn>

							<MDBBtn className="btn rounded ml-2 mr-2" onClick={() => this.publishCommand(`end slideshow`)}>

								<MDBIcon size="2x" far icon="stop-circle" className="blue-grey-text" />
							</MDBBtn>

						</MDBBtnGroup>
					</div>
				</MDBCol>
				<MDBCol md='6' className="mb-2">
					<p className="h6 text-center">Other</p>
					<div className="text-center">
						<MDBBtnGroup size="sm" className="mb-2">

							<MDBBtn className="btn rounded ml-2 mr-2" onClick={() => this.publishCommand(`reload`)}>

								<MDBIcon size="2x" fas icon="sync" className="blue-grey-text" />
							</MDBBtn>
							<MDBBtn className="btn rounded ml-2 mr-2" onClick={() => this.publishCommand(`no focus`)}>

								<MDBIcon size="2x" far icon="eye-slash" className="blue-grey-text" />
							</MDBBtn>
						</MDBBtnGroup>
					</div>
				</MDBCol>

				<MDBCol md='12' className="mb-2">
					<p className="h6 text-center">Layout</p>
					<div className="text-center">
						<MDBBtnGroup size="sm" className="mb-2">
							<MDBBtn className="btn rounded ml-1 mr-1" onClick={() => this.publishCommand(`save layout`)}>

								<MDBIcon size="2x" far icon="save" className="blue-grey-text" />
							</MDBBtn>
							<MDBBtn className="btn rounded ml-1 mr-1" onClick={() => this.publishCommand(`reset layout`)}>

								<MDBIcon size="2x" fas icon="redo-alt" className="blue-grey-text" />
							</MDBBtn>
							<MDBBtn className="btn rounded ml-1 mr-1" onClick={() => this.publishCommand(`add block`)}>

								<MDBIcon size="2x" fas icon="plus" className="blue-grey-text" />
							</MDBBtn>
						</MDBBtnGroup>
					</div>
				</MDBCol>








				<MDBCol md='6' className="mb-2">
					<p className="h6 text-center">Overlays</p>
					<div className="text-center">
						<MDBBtnGroup size="sm" className="mb-2">
							<MDBBtn className="btn rounded ml-1 mr-1" onClick={() => this.publishCommand(`toggle metrics`)}>

								<MDBIcon size="2x" fas icon="chart-area" className="blue-grey-text" />
							</MDBBtn>
							<MDBBtn className="btn rounded ml-1 mr-1" onClick={() => this.publishCommand(`toggle names`)}>

								<MDBIcon size="2x" fas icon="signature" className="blue-grey-text" />
							</MDBBtn>
							<MDBBtn className="btn rounded ml-1 mr-1" onClick={() => this.publishCommand(`toggle ids`)}>

								<MDBIcon size="2x" far icon="id-card" className="blue-grey-text" />
							</MDBBtn>
						</MDBBtnGroup>
					</div>
				</MDBCol>
				<MDBCol md='6' className="mb-2">
					<p className="h6 text-center">Navigation</p>
					<div className="text-center">
						<MDBBtnGroup size="sm" className="mb-2">
							<MDBBtn className="btn rounded ml-1 mr-1" onClick={() => this.publishCommand(`scroll up`)}>

								<MDBIcon size="2x" far icon="arrow-alt-circle-up" className="blue-grey-text" />
							</MDBBtn>
							<MDBBtn className="btn rounded ml-1 mr-1" onClick={() => this.publishCommand(`scroll down`)}>

								<MDBIcon size="2x" far icon="arrow-alt-circle-down" className="blue-grey-text" />
							</MDBBtn>
							<MDBBtn className="btn rounded ml-1 mr-1" onClick={() => this.publishCommand(`zoom out`)}>

								<MDBIcon size="2x" fas icon="search-minus" className="blue-grey-text" />
							</MDBBtn>
						</MDBBtnGroup>
					</div>
				</MDBCol>














			</MDBRow>
		)
	}

	buildDropdownItems() {
		return _.sortBy(this.state.blocks, "name").map(block => {
			return (<option value={block.id}>{block.name}</option>)
		})
	}

	buildBlockHelpers() {
		return (
			<div className="text-center">
				<p className="h6 text-center">Block</p>
				<select onChange={this.changeSelectedBlock} value={this.state.selectedBlock} className="browser-default custom-select mb-2" id="selectedBlock">
					<option>Select Block</option>
					{this.buildDropdownItems()}
				</select>
				<MDBRow>
					<MDBCol md='6' className="mb-2">
						<div className="text-center">
							<MDBBtnGroup size="sm" className="mb-2">
								<MDBBtn className="btn rounded" onClick={() => this.publishCommand(`show ${this.state.selectedBlock}`)}>
									<MDBIcon size="2x" far icon="eye" className="blue-grey-text" />
								</MDBBtn>
								<MDBBtn className="btn rounded" onClick={() => this.publishCommand(`no focus`)}>
									<MDBIcon size="2x" far icon="eye-slash" className="blue-grey-text" />
								</MDBBtn>
							</MDBBtnGroup>
						</div>
					</MDBCol>
					<MDBCol md='6' className="mb-2">
						<div className="text-center">
							<MDBBtnGroup size="sm" className="mb-2">

								<MDBBtn className="btn rounded" onClick={() => this.publishCommand(`scroll to ${this.state.selectedBlock}`)}>
									<MDBIcon size="2x" fas icon="arrows-alt-v" className="blue-grey-text" />
								</MDBBtn>
								<MDBBtn className="btn rounded" onClick={() => this.publishCommand(`zoom to ${this.state.selectedBlock}`)}>
									<MDBIcon size="2x" fas icon="search-plus" className="blue-grey-text" />
								</MDBBtn>
								<MDBBtn className="btn rounded" onClick={() => this.publishCommand(`zoom out`)}>
									<MDBIcon size="2x" fas icon="search-minus" className="blue-grey-text" />
								</MDBBtn>
							</MDBBtnGroup>
						</div>
					</MDBCol>
				</MDBRow>
			</div>
		)
	}


	async getBlocks() {
		await this.api.getBoard(this.boardId).then(
			ret => {
				if (ret) {
					console.log('ret:')
					console.log(ret)
					let data = ret.blocks
					this.setState({
						blocks: data
					})
				}
			}
		)
	}

	async publishCommand(command = null) {
		if (command == null) {
			command = this.state.commandBoxText;
		}

		let data = {
			topic_arn: "arn:aws:sns:us-east-1:553326393419:progrzn.fifo",
			message: {
				command: command,
				unique_id: uuidv4()
			},
			subject: this.boardId.toString(),
			message_attributes: {},
			message_group_id: this.boardId.toString()
		}
		await this.api.publishMessage(data).then(
			ret => {
				if (ret) {
					console.log(ret);
				}
			}
		)
	}

	render() {
		return (
			<MDBContainer style={{

			}}>

				<MDBNav className="nav-tabs flex-sm-row mt-1 mb-0" style={{
					border: "none",

				}}>
					<MDBNavItem className="pl-1 pr-1" style={{
						marginBottom: "-10px",

					}}>
						{/* <MDBNavLink className="flex-sm-fill text-sm-center" link to="#" active={this.state.activeItem === "1"} onClick={this.toggle("1")} role="tab" >
                  General
                </MDBNavLink> */}
						<MDBBtn className="flex-sm-fill text-sm-center" active={this.state.activeItem === "1"} onClick={this.toggle("1")} role="tab" >
							General
						</MDBBtn>
					</MDBNavItem>
					<MDBNavItem className="pl-1 pr-1" style={{
						marginBottom: "-10px",

					}}>
						{/* <MDBNavLink className="flex-sm-fill text-sm-center" link to="#" active={this.state.activeItem === "2"} onClick={this.toggle("2")} role="tab" >
                  Block
                </MDBNavLink> */}
						<MDBBtn className="flex-sm-fill text-sm-center" active={this.state.activeItem === "2"} onClick={this.toggle("2")} role="tab" >
							Block
						</MDBBtn>
					</MDBNavItem>
					<MDBNavItem className="pl-1 pr-1" style={{
						marginBottom: "-10px",

					}}>
						{/* <MDBNavLink className="flex-sm-fill text-sm-center" link to="#" active={this.state.activeItem === "3"} onClick={this.toggle("3")} role="tab" >
                  Custom
                </MDBNavLink> */}
						<MDBBtn className="flex-sm-fill text-sm-center" active={this.state.activeItem === "3"} onClick={this.toggle("3")} role="tab" >
							Custom
						</MDBBtn>
					</MDBNavItem>
				</MDBNav>
				<MDBTabContent activeItem={this.state.activeItem} >
					<MDBTabPane tabId="1" role="tabpanel">
						<MDBRow>

							<MDBCol md="6">

								<MDBCard>
									<MDBCardBody style={{
										backgroundColor: "black",
										color: "white"
									}}>

										{this.buildBaseCommands()}

									</MDBCardBody>
								</MDBCard>
							</MDBCol>
						</MDBRow>
					</MDBTabPane>
					<MDBTabPane tabId="2" role="tabpanel">
						<MDBRow>
							<MDBCol md="6">
								<MDBCard>
									<MDBCardBody style={{
										backgroundColor: "black",
										color: "white"
									}}>

										{this.buildBlockHelpers()}

									</MDBCardBody>
								</MDBCard>
							</MDBCol>
						</MDBRow>

					</MDBTabPane>
					<MDBTabPane tabId="3" role="tabpanel">

						<MDBRow>
							<MDBCol md="6">
								<MDBCard>
									<MDBCardBody style={{
										backgroundColor: "black",
										color: "white"
									}}>
										<form>
											<p className="h6 text-center">Manual</p>
											<label
												htmlFor="defaultFormCardNameEx"
												className="grey-text font-weight-light"
											>
												Command
											</label>
											<input className="form-control" ref={(input) => { this.nameInput = input; }} placeholder="Enter Command" onKeyPress={event => { if (event.key === 'Enter') { this.publishCommand() } }} value={this.state.commandBoxText ? this.state.commandBoxText : ""} onChange={e => this.setState({ commandBoxText: e.target.value })} type="text"></input>

											<div className="text-center py-4">
												<MDBBtn onClick={() => this.publishCommand()} className="btn rounded" type="submit">
													<span className="blue-grey-text">Submit</span>
													<MDBIcon far icon="paper-plane" className="ml-2 blue-grey-text" />
												</MDBBtn>
											</div>
										</form>
									</MDBCardBody>
								</MDBCard>
							</MDBCol>

						</MDBRow>
					</MDBTabPane>
				</MDBTabContent>

			</MDBContainer>
		)
	}


}

export default Remote
