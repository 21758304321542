import React, { forwardRef } from "react";
import { WidthProvider, Responsive } from "react-grid-layout";
// import Iframe from 'react-iframe';
// import Iframe from './Iframe.js';
import _ from "lodash";
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import './styles.css';
import 'react-keyed-file-browser/dist/react-keyed-file-browser.css';
import Api from './Api';
import VideoCover from 'react-video-cover'
// import {Animated} from "react-animated-css";
import ChecklistApp from "./ChecklistApp";
import ReactCardFlip from 'react-card-flip';
import Moment from 'moment'
import FileBrowser, { Icons } from 'react-keyed-file-browser'
import keydown, { Keys } from 'react-keydown';
import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import { CloseOutlined } from "@material-ui/icons";
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { TweenMax, TweenLine, TweenLite } from 'gsap';
import zoom from './zoom';

// var ws = new WebSocket("wss://qbqljzxguj.execute-api.us-east-1.amazonaws.com/prod");

var topics = [
	'progrzn.fifo'
]

// console.log = () => {};

var root = document.documentElement;
var body = document.body;
// var pages = document.querySelectorAll(".page");
// var tiles = document.querySelectorAll(".tile");

function animateHero(fromHero, toHero) {


	var clone = fromHero.cloneNode(true);

	var from = calculatePosition(fromHero);
	var to = calculatePosition(toHero);

	TweenLite.set([fromHero, toHero], { visibility: "hidden" });
	TweenLite.set(clone, { position: "absolute", margin: 0 });

	body.appendChild(clone);

	var style = {
		x: to.left - from.left,
		y: to.top - from.top,
		width: to.width,
		height: to.height,
		autoRound: false,
		ease: "Power1.easeOut",
		onComplete: onComplete
	};

	TweenLite.set(clone, from);
	TweenLite.to(clone, 0.3, style)

	function onComplete() {

		TweenLite.set(toHero, { visibility: "visible" });
		body.removeChild(clone);
	}
}

function calculatePosition(element) {

	var rect = element.getBoundingClientRect();

	var scrollTop = window.pageYOffset || root.scrollTop || body.scrollTop || 0;
	var scrollLeft = window.pageXOffset || root.scrollLeft || body.scrollLeft || 0;

	var clientTop = root.clientTop || body.clientTop || 0;
	var clientLeft = root.clientLeft || body.clientLeft || 0;

	return {
		top: Math.round(rect.top + scrollTop - clientTop),
		left: Math.round(rect.left + scrollLeft - clientLeft),
		height: rect.height,
		width: rect.width,
	};
}


function shuffle(array) {
	var currentIndex = array.length, temporaryValue, randomIndex;

	// While there remain elements to shuffle...
	while (0 !== currentIndex) {

		// Pick a remaining element...
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex -= 1;

		// And swap it with the current element.
		temporaryValue = array[currentIndex];
		array[currentIndex] = array[randomIndex];
		array[randomIndex] = temporaryValue;
	}

	return array;
}


const tableIcons = {
	Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
	Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
	Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
	DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
	Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
	Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
	ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
	ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
	ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};
const ResponsiveReactGridLayout = WidthProvider(Responsive);


// console.log = () => {}

let originalLayouts = getFromLS("layouts") || {};

// console.log('originalLayouts:')
// console.log(originalLayouts)
/**
 * This layout demonstrates how to use a grid with a dynamic number of elements.
 */
// const data = new Api().getData()
let getItem = (val) => {
	return JSON.parse(localStorage.getItem(val));
};
const removeStyle = {
	//    position: "absolute",
	//    right: "2px",
	//    top: 0,
	cursor: "pointer"
};

const editStyle = {
	//    position: "absolute",
	//    right: "20px",
	//    top: 0,
	cursor: "pointer"
};

const removeStyle2 = {
	position: 'fixed',
	// right: '20px',
	// top: '0px',
	cursor: 'pointer',
	// color: 'white',
	// fontSize: '25pt',
	// background: 'transparent'
}
const removeStyle3 = {

	// right: '20px',
	// top: '0px',
	cursor: 'pointer',
	// color: 'white',
	// fontSize: '25pt',
	// background: 'transparent'
}

function disableScrolling() {
	var x = window.scrollX;
	var y = window.scrollY;
	window.onscroll = function () { window.scrollTo(x, y); };
}

function enableScrolling() {
	window.onscroll = function () { };
}

const { ENTER, TAB } = Keys;

class BoardView extends React.PureComponent {

	constructor(props) {
		super(props);

		this.state = {
			table_data: null,
			table_columns: [
				{ title: "Name", field: "name" },
				{ title: "Created", field: "created", editable: 'never' },
				{ title: "Updated At", field: "updated_at", editable: 'never' },
				{ title: "Completion", field: "completion", editable: 'never' }
			],
			files: [
				{
					key: 'photos/animals/cat in a hat.png',
					modified: +Moment().subtract(1, 'hours'),
					size: 1.5 * 1024 * 1024,
				},
				{
					key: 'photos/animals/kitten_ball.png',
					modified: +Moment().subtract(3, 'days'),
					size: 545 * 1024,
				},
				{
					key: 'photos/animals/elephants.png',
					modified: +Moment().subtract(3, 'days'),
					size: 52 * 1024,
				},
				{
					key: 'photos/funny fall.gif',
					modified: +Moment().subtract(2, 'months'),
					size: 13.2 * 1024 * 1024,
				},
				{
					key: 'photos/holiday.jpg',
					modified: +Moment().subtract(25, 'days'),
					size: 85 * 1024,
				},
				{
					key: 'documents/letter chunks.doc',
					modified: +Moment().subtract(15, 'days'),
					size: 480 * 1024,
				},
				{
					key: 'documents/export.pdf',
					modified: +Moment().subtract(15, 'days'),
					size: 4.2 * 1024 * 1024,
				},
			],
			isFlipped: false,
			showName: true,
			showId: true,
			showMetric: true,
			data: [],
			items: [],
			newCounter: 0,
			// board_id: window.location.pathname.split('/')[1] == "board" ? parseInt(window.location.pathname.split('board/')[1].replace("/", "")) : 1,
			layouts: JSON.parse(JSON.stringify(originalLayouts)),
			showHelp: false,
			showDetail: false,
			showFocus: false,
			focus_id: null,
			detail_id: null,
			blockName: null,
			blockKeywords: null,
			contentUrl: null,
			blockMetricKeywords: null,
			blockBackgroundStyle: null,
			blockBackground: null,
			blockMetricValue: null,
			blockMetricLabel: null,
			blockMetricBackgroundStyle: null,
			blockMetricBackground: null,
			kewordResults: null,
			shouldSlideshow: false,
			lastTransform: '',
			commandBoxText: '',
			showActionButtons: false,
			events: [],
			convertedGif: null
		};
		this.annyang = null;
		this.slideshowInterval = null;
		this.api = new Api()
		this.isDragging = false;
		this.isResizing = false;
		this.onLayoutChange = this.onLayoutChange.bind(this)
		this.onAddItem = this.onAddItem.bind(this);
		this.onBreakpointChange = this.onBreakpointChange.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.generateBackground = this.generateBackground.bind(this);
		this.generateMetricBackground = this.generateMetricBackground.bind(this);
		this.zoomOut = this.zoomOut.bind(this);
		this.zoomIn = this.zoomIn.bind(this);
		this.zoomToBlock = this.zoomToBlock.bind(this);
		this.zoomFromBlock = this.zoomFromBlock.bind(this);
		this.startSlideshow = this.startSlideshow.bind(this);
		this.endSlideshow = this.endSlideshow.bind(this);
		this.submitCommand = this.submitCommand.bind(this);
		this.generateBackgroundFromContent = this.generateBackgroundFromContent.bind(this)
		this.generateBackgroundFromUrl = this.generateBackgroundFromUrl.bind(this)
		this.configureWebsocket = this.configureWebsocket.bind(this)
		this.getData = this.getData.bind(this)
		this.check = this.check.bind(this)
		this.timeout = 250
		this.connectInterval = null
	}

	static get defaultProps() {
		return {
			className: "layout",
			cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
			rowHeight: 30
		};
	}

	// @keydown( 'enter' ) // or specify `which` code directly, in this case 13
	// submit( event ) {
	//     console.log('woot');
	// if (this.state.showActionButtons){
	//     this.submitCommand()
	// }
	// }

	@keydown(ENTER, TAB) // or specify `which` code directly, in this case 13
	autocomplete(event) {
		console.log(event);
		if (event.which === ENTER) {
			if (this.state.showActionButtons) {
				this.submitCommand()
			}
		}
		else {
			console.log('q', this.state.showActionButtons);
			this.setState({ showActionButtons: this.state.showActionButtons ? false : true, commandBoxText: "" }, () => {
				if (this.state.showActionButtons) {
					setTimeout(() => {
						if (this.nameInput !== null) {

							var transform = document.body.style.transform;
							var scale = 1;
							var newtranslatex = 0;
							var newtranslatey = 0;
							var newtransform = ''
							if (transform.includes('scale')) {
								var scale = parseFloat(transform.split('scale(')[1].split(')')[0]);

							}
							if (transform.includes('translate')) {
								var translatex = parseFloat(transform.split('translate(')[1].split('px')[0]);
								var translatey = parseFloat(transform.split('translate(')[1].split(',')[1].split(")")[0])
								var newscale = 1 / scale;
								newtranslatex = translatex * newscale * -1;
								newtranslatey = document.body.getBoundingClientRect().top * newscale * -1;
								newtransform = `translate(${newtranslatex}px, ${newtranslatey}px) scale(${newscale})`;
								// this.nameInput.style.transform = newtransform;
								var el = document.getElementById("commandContainer");
								if (el !== null) {
									TweenMax.to(el, .5, {
										// top: window.pageYOffset,
										// left: pos.left,
										// right: pos.right,
										// bottom: pos.bottom,
										transform: newtransform,
										// zoom: newscale,
										// transform: `scale(${newscale})`,
										// height: "100vh",
										// width: "100vw",
										height: window.innerHeight * newscale,
										width: window.innerWidth * newscale,
										className: el.className + " fullscreen",
										ease: "sine.in"
									});
								}

								// el.style.transform = newtransform;
								// el.style.height = window.innerHeight;
								// el.style.width = window.innerWidth;
								// this.nameInput.focus();
								// el.focus();

								var el2 = document.getElementById("commandBox");
								el2.autofocus = true;
								el2.focus();
							}



							disableScrolling();


						}

					}, 100);

				}
				else {
					enableScrolling();
				}
			})
		}

		// console.log(event)
		// let el = this.state.items.find(x => x.name === "Amazing Kangaroos")
		// console.log(el)
		// this.focusBlock(el.i)
		// do something, or not, with the keydown event, maybe event.preventDefault()

	}


	getCommands() {
		var commands = {
			'go home': function () { window.location.href = '/' },
			'show help': this.help.bind(this),
			'close help': this.closeHelp.bind(this),
			'start slideshow': this.startSlideshow.bind(this),
			'stop slideshow': this.endSlideshow.bind(this),
			'toggle metrics': this.triggerMetricOverlays.bind(this),
			'toggle names': this.triggerNameOverlays.bind(this),
			'toggle ids': this.triggerIdOverlays.bind(this),
			'save layout': this.saveLayout.bind(this),
			'reset layout': this.resetLayout.bind(this),
			'add block': this.onAddItem.bind(this),
			'no focus': this.offFocus.bind(this),
			'scroll up': this.scrollUp.bind(this),
			'scroll down': this.scrollDown.bind(this),
			'zoom out': this.zoomOut.bind(this),
			'reload': this.reload.bind(this)
		};

		for (var i in this.state.items) {
			commands['show ' + this.state.items[i].name] = this.focusBlock.bind(this, this.state.items[i].i);
			commands['edit ' + this.state.items[i].name] = this.showDetail.bind(this, this.state.items[i].i);
			commands['scroll to ' + this.state.items[i].name] = this.scrollToBlock.bind(this, this.state.items[i].i);
			commands['zoom to ' + this.state.items[i].name] = this.zoomIn.bind(this, this.state.items[i].i);
			commands['show ' + this.state.items[i].i] = this.focusBlock.bind(this, this.state.items[i].i);
			commands['edit ' + this.state.items[i].i] = this.showDetail.bind(this, this.state.items[i].i);
			commands['scroll to ' + this.state.items[i].i] = this.scrollToBlock.bind(this, this.state.items[i].i);
			commands['zoom to ' + this.state.items[i].i] = this.zoomIn.bind(this, this.state.items[i].i);
		}

		return commands;
	}

	executeCommand(command) {
		// If command in commands, execute it
		var commands = this.getCommands();
		if (command in commands) {
			commands[command]();
		}
		else {
			console.log('Command not found:', command);
		}
	}

	// @keydown( 'tab' ) // or specify `which` code directly, in this case 13
	// submit( event ) {
	//     // console.log(event)
	//     let el = this.state.items.find(x => x.name === "Amazing Kangaroos")
	//     // console.log(el)
	//     this.focusBlock(el.i)
	//   // do something, or not, with the keydown event, maybe event.preventDefault()
	//
	// }

	getData(event) {
		// this.setState({
		//     events: [events, ...this.state.events]
		// }, ()=>{
		//     console.log(this.state.events)
		// })
		if (event.message.command) {
			// this.annyang.trigger(event.message.command);
			this.executeCommand(event.message.command);
		}
		else if (event.message.img) {

			this.setState({
				blockBackground: event.message.img
			})
		}


	}

	triggerNameOverlays() {
		this.setState({
			showName: this.state.showName ? false : true
		})
	}

	submitCommand() {
		if (this.annyang !== null) {
			console.log('commandBoxText:', this.state.commandBoxText);
			this.setState({
				showActionButtons: false
			}, () => {
				enableScrolling();
				this.annyang.trigger(this.state.commandBoxText);
			})

		}
	}

	triggerIdOverlays() {
		this.setState({
			showId: this.state.showId ? false : true
		})
	}

	triggerMetricOverlays() {
		// console.log('triggering metric overlays')
		this.setState({

			showMetric: this.state.showMetric ? false : true
		})
	}

	zoomToBlock(id) {


		this.setState({
			zoomId: id
		}, () => {
			var el = document.getElementById(`block_${id}`);
			var el2 = document.getElementById(`block_${id}_image`);
			var screen = window.scrollTop;
			// console.log("el:", el.className);
			var pos = calculatePosition(document.documentElement);
			var transform = document.body.style.transform;
			var scale = 1;
			if (transform.includes('scale')) {
				var scale = parseFloat(transform.split('scale(')[1].split(')')[0]);

			}
			var translatex = parseFloat(transform.split('translate(')[1].split('px')[0]);
			var translatey = parseFloat(transform.split('translate(')[1].split(',')[1].split(")")[0])
			console.log('scale:', scale);
			var newscale = 1 / scale;
			var newtranslatex = translatex * newscale * -1;
			var newtranslatey = document.body.getBoundingClientRect().top * newscale * -1;

			console.log(newscale);
			this.setState({
				lastTransform: el.style.transform,
				lastHeight: el.style.height,
				lastWidth: el.style.width
			}, () => {
				// var el = document.getElementById(`block_${id}`);
				if (el !== null) {
					TweenMax.to(el, 2.5, {
						// top: window.pageYOffset,
						// left: pos.left,
						// right: pos.right,
						// bottom: pos.bottom,
						transform: `translate(${newtranslatex}px, ${newtranslatey}px)`,
						// zoom: newscale,
						// transform: `scale(${newscale})`,
						// height: "100vh",
						// width: "100vw",
						height: window.innerHeight * newscale,
						width: window.innerWidth * newscale,
						className: el.className + " fullscreen",
						ease: "sine.in"
					});
					TweenMax.to(el2, 3.5, {
						opacity: .9,
						ease: "sine.in"
						// ease: "easeOut"
					}, () => {

					});
				}

			})


			// var el3 = el.cloneNode(true);
			// el3.className += "fullscreen";
			// animateHero(el, el3);
			// this.scrollToBlock(id);

		})

		// el.requestFullscreen();
	}

	zoomFromBlock(id) {
		this.setState({
			zoomId: null
		}, () => {
			var fs = document.querySelector('.fullscreen');
			if (fs !== null) {
				var el = document.getElementById(`block_${id}`);
				var el2 = document.getElementById(`block_${id}_image`);

				// TweenMax.to(el, 3, {
				//   className: el.className.replace("fullscreen", ""),
				//   ease: "smooth"
				// });

				// var pos = calculatePosition(el);
				if (el !== null) {
					TweenMax.to(el, 3.5, {
						// top: 0,
						transform: this.state.lastTransform,
						height: this.state.lastHeight,
						width: this.state.lastWidth,
						// left: pos.left,
						// height: pos.height,
						className: el.className.replace("fullscreen", ""),
						ease: "Sine.easeOut"
						// ease: "easeOut"
					});
					// TweenMax.to(el, 3, {
					//   className:"-=fullscreen"
					// });
					// fs.className -= "fullscreen";
					// if (el !== null){
					//     el.className -= "fullscreen"
					// }

					TweenMax.to(el2, 3.5, {
						opacity: .4,
						ease: "Sine.easeOut"
					}, () => {

					});
				}

			}

		})

	}

	saveLayout() {
		let modified_layouts = this.state.layouts
		for (var i in modified_layouts[this.state.breakpoint]) {
			modified_layouts[this.state.breakpoint][i].showName = this.state.showName
			modified_layouts[this.state.breakpoint][i].showId = this.state.showId
			modified_layouts[this.state.breakpoint][i].showMetric = this.state.showMetric
		}

		this.api.updateBoard(this.boardId, { layout: modified_layouts })
	}

	resetLayout() {
		this.setState({ layouts: {} });
	}

	resizeIFrameToFitContent(iFrame) {

		iFrame.width = iFrame.contentWindow.document.body.scrollWidth;
		iFrame.height = iFrame.contentWindow.document.body.scrollHeight;
	}

	showDetail(id) {
		if (this.isDragging || this.isResizing) {
			return
		}
		// console.log('showDetail!')
		let item = this.state.items.filter(obj => {
			return parseInt(obj.i) === parseInt(id)
		})[0]
		// console.log("el:", el)
		// console.log("items:", this.state.items)
		console.log('item:', item)
		if (this.state.blockName === null) {
			this.onNameChange(item.name)
		}
		if (this.state.blockBackground === null) {
			this.onBackgroundChange(item.img)
		}
		if (this.state.blockBackgroundStyle === null) {
			this.onBackgroundStyleChange(item.background_style)
		}
		if (this.state.blockMetricBackgroundStyle === null) {
			this.onMetricBackgroundStyleChange(item.metric_background_style)
		}


		// if (this.state.blockKeywords == null){
		//     this.onKeywordsChange(item)
		// }
		if (this.state.blockMetricValue === null) {
			this.onMetricValueChange(item.metric_value)
		}
		if (this.state.blockMetricLabel === null) {
			this.onMetricLabelChange(item.metric_label)
		}
		if (this.state.blockMetricBackground === null) {
			this.onMetricBackgroundChange(item.metric_background)
		}
		this.setState({
			detail_id: id,
			showDetail: true
		})
	}

	onItemClick = (el) => {
		// idiomatic way to prevent a click when resizing
		if (!this.isDragging && !this.isResizing) {
			// this.showDetail(el);
		}

	}

	onDrag = (e) => {
		this.isDragging = true;
	}
	onDragStop = (e) => {
		// HACK: add some delay otherwise a click event is sent
		setTimeout((obj) => { obj.isDragging = false }, 200, this)
	}
	onResizeStart = (e) => {
		this.isResizing = true;
	}
	onResizeStop = (e) => {
		// HACK: add some delay otherwise a click event is sent
		setTimeout((obj) => { obj.isResizing = false }, 200, this)
	}

	handleClick(e) {
		e.preventDefault();
		this.setState(prevState => ({ isFlipped: !prevState.isFlipped }));
	}
	createChecklist(e) {
		// console.log('createchecklist')
		// <ChecklistApp
		//     todos={getItem('todos')}
		//     finished={getItem('finished')}
		//     done={getItem('done')}
		// />
		// console.log(e.list_items)
		let todos = e.list_items.map((item) => {
			return {
				task: item.value,
				completed: item.checked ? true : false,
				id: item.id
			}
		})
		let total = e.list_items.length
		// console.log('total:')
		// console.log(total)
		let finished = e.list_items.filter(x => x.checked === true).length
		// console.log('finished:')
		// console.log(finished)
		return (

			<ChecklistApp
				key={e.id}
				owner={e}
				name={e.name}
				todos={todos}
				finished={finished}
				done={finished / total * 100}
				onAdd={this.addListItem.bind(this)}
				onUpdate={this.updateListItem.bind(this)}
				onDelete={this.deleteListItem.bind(this)}
			/>
		)
	}
	isVideo(url) {
		let video_extensions = [
			'webm',
			'mpg',
			'mp2',
			'mpeg',
			'mpe',
			'mpv',
			'ogg',
			'mp4',
			'm4p',
			'm4v',
			'avi',
			'wmv',
			'mov',
			'qt',
			'flv',
			'swf',
			'avchd'
		]
		url = url.toLowerCase()
		for (var i in video_extensions) {
			if (url.endsWith(video_extensions[i])) {
				return true
			}
		}
		return false
	}
	isImage(url) {
		let img_extensions = [
			"ase",
			"art",
			"bmp",
			"blp",
			"cd5",
			"cit",
			"cpt",
			"cr2",
			"cut",
			"dds",
			"dib",
			"djvu",
			"egt",
			"exif",
			"gif",
			"gpl",
			"grf",
			"icns",
			"ico",
			"iff",
			"jng",
			"jpeg",
			"jpg",
			"jfif",
			"jp2",
			"jps",
			"lbm",
			"max",
			"miff",
			"mng",
			"msp",
			"nitf",
			"ota",
			"pbm",
			"pc1",
			"pc2",
			"pc3",
			"pcf",
			"pcx",
			"pdn",
			"pgm",
			"PI1",
			"PI2",
			"PI3",
			"pict",
			"pct",
			"pnm",
			"pns",
			"ppm",
			"psb",
			"psd",
			"pdd",
			"psp",
			"px",
			"pxm",
			"pxr",
			"qfx",
			"raw",
			"rle",
			"sct",
			"sgi",
			"rgb",
			"int",
			"bw",
			"tga",
			"tiff",
			"tif",
			"vtf",
			"xbm",
			"xcf",
			"xpm",
			"3dv",
			"amf",
			"ai",
			"awg",
			"cgm",
			"cdr",
			"cmx",
			"dxf",
			"e2d",
			"egt",
			"eps",
			"fs",
			"gbr",
			"odg",
			"svg",
			"stl",
			"vrml",
			"x3d",
			"sxd",
			"v2d",
			"vnd",
			"wmf",
			"emf",
			"art",
			"xar",
			"png",
			"webp",
			"jxr",
			"hdp",
			"wdp",
			"cur",
			"ecw",
			"iff",
			"lbm",
			"liff",
			"nrrd",
			"pam",
			"pcx",
			"pgf",
			"sgi",
			"rgb",
			"rgba",
			"bw",
			"int",
			"inta",
			"sid",
			"ras",
			"sun",
			"tga"
		];
		url = url.toLowerCase()
		for (var i in img_extensions) {
			if (url.endsWith(img_extensions[i].toLowerCase())) {
				return true
			}
		}
		return false
	}
	showElement() {
		let el = this.state.items.find(x => x.i === this.state.focus_id)
		// console.log('el:')
		// console.log(el)


		return (
			<div style={{ cursor: 'pointer', boxShadow: "darkgrey 0px 0px 20px -8px", background: 'black', opacity: el.i === this.state.focus_id ? 1 : this.state.showFocus ? .1 : 1 }} key={el.i} data-grid={el}>

				<div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>

					<div className="video-background" style={{ opacity: .4, width: '100%', height: '100vh', position: 'absolute' }}>
						{this.isVideo(el.img) ? (

							<VideoCover
								videoOptions={{
									src: el.img,
									ref: videoRef => {
										this.videoRef = videoRef;
									},
									loop: true,
									autoPlay: true,
									muted: true
								}}
							>
							</VideoCover>

						) : (this.isImage(el.img)) ? (
							<img id={`block_${el.i}_image`} style={{ opacity: .4, width: '100%', height: '100%', objectFit: el.background_style }} src={el.img} alt="">
							</img>
						) : (

							<iframe frameBorder="0" style={{ width: "100%", height: "100%" }}
								src={el.img}>
							</iframe>


						)}

					</div>
					<div className="row">
						<div className="col-md-6">
							<div style={{ padding: '25px', marginTop: '45px', justifyContent: 'center', alignItems: 'center' }}>
								<div style={{ maxWidth: "100%" }}>
									<MaterialTable
										icons={tableIcons}
										columns={this.state.table_columns}
										data={this.state.table_data}
										title="Checklists"
										detailPanel={rowData => {
											return (
												<div
													style={{
														textAlign: 'center',
														color: 'white',
														backgroundColor: 'black',
														borderWidth: 'medium',
														borderColor: 'white',
														borderStyle: 'outset'
													}}
												>
													{this.createChecklist(rowData)}
												</div>
											)


										}}
										editable={{
											onRowAdd: newData =>
												new Promise((resolve, reject) => {
													// console.log('adding!')
													setTimeout(() => {
														this.addChecklist({
															name: newData.name,
															list_items: newData.list_items
														})
														resolve()
													}, 1000)
												}),
											onRowUpdate: (newData, oldData) =>
												new Promise((resolve, reject) => {
													// console.log('updating!')
													// console.log(this.state)
													setTimeout(() => {
														{

															const table_data = this.state.table_data;
															const index = table_data.indexOf(oldData);
															table_data[index] = newData;
															this.updateChecklist({
																name: newData.name,
																id: newData.id,
																list_items: newData.list_items
															})
															this.setState({ table_data }, () => {
																this.forceUpdate();
																resolve()
															});
														}
														resolve()
													}, 1000)
												}),
											onRowDelete: oldData =>
												new Promise((resolve, reject) => {
													// console.log('deleting!')
													setTimeout(() => {
														{
															let table_data = this.state.table_data;
															const index = table_data.indexOf(oldData);
															this.deleteChecklist(oldData)
															table_data.splice(index, 1);
															this.setState({ table_data }, () => {
																this.forceUpdate();
																resolve()
															});
														}
														resolve()
													}, 1000)
												}),
										}}
									/>
								</div>

							</div>
						</div>
						<div className="col-md-6">
							<div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: '45px' }}>

								<FileBrowser
									files={this.state.files}
									icons={Icons.FontAwesome(4)}

									onCreateFolder={this.handleCreateFolder}
									onCreateFiles={this.handleCreateFiles}
									onMoveFolder={this.handleRenameFolder}
									onMoveFile={this.handleRenameFile}
									onRenameFolder={this.handleRenameFolder}
									onRenameFile={this.handleRenameFile}
									onDeleteFolder={this.handleDeleteFolder}
									onDeleteFile={this.handleDeleteFile}
								/>
							</div>
						</div>
					</div>



					{this.state.showId && (
						<div className={"numberContainer"}>
							<span className={"numberBall"}>
								{`${el.i}`}
							</span>
						</div>
					)}

					{this.state.showName && (
						<div style={{ width: '100%', position: 'absolute', bottom: 20, background: '#00000021' }}>
							<svg style={{ width: '100%', display: 'flex', height: 'auto', position: 'relative' }} width="100%" height="100%" viewBox="0 0 300 24">
								<text textAnchor="middle" style={{
									textShadow: 'rgb(187, 186, 186) -2px 1px 4px',
									textAnchor: 'inherit',
									color: 'white'

								}} font="18px Helvetica, Arial" stroke="none" fill="#ffffff" textLength='120' lengthAdjust="spacing" x='5' y="18">{`${el.name}`}</text>
							</svg>
						</div>
					)}
					{this.state.showMetric && (
						<div style={{ background: '#0000004f', position: 'absolute', width: '100%', height: '50px', right: '0px', top: 0 }}>
							<span style={{
								fontWeight: 'bold',
								fontSize: 'x-large',

								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								position: 'absolute',
								height: '100%',
								textAlign: 'center',
								color: 'white',
								zIndex: 1,
								left: '10%',
								right: '10%',

								background: 'transparent',
								textShadow: '2px 3px 3px black'
							}} className="text">{el.metric_value} {el.metric_label}</span>
							{el.metric_background && (
								<img style={{ width: '100%', height: '100%', objectFit: el.metric_background_style, position: 'absolute', top: 0 }} src={el.metric_background} alt="">
								</img>
							)}

						</div>
					)}


				</div>

				{!this.state.focus_id && (
					<span
						className="remove"
						style={removeStyle}
						onClick={this.onRemoveItem.bind(this, el.i)}
					>
						x
					</span>
				)}



			</div>
		)
	}


	createElement(el) {

		// console.log("el: ", el);
		// console.log('in createElement')
		// console.log(this.state.showFocus)
		// console.log(this.state.focus_id)
		//
		// console.log((this.state.showFocus && el.i !== this.state.focus_id))

		return (

			<div className={"block"} id={`block_${el.i}`} onClick={this.onItemClick.bind(this, el)} style={{ cursor: 'pointer', boxShadow: "darkgrey 0px 0px 20px -8px", background: "none", opacity: el.i === this.state.focus_id ? 1 : this.state.showFocus ? .1 : 1 }} key={el.i} data-grid={el}>

				<div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>

					<div className="video-background" style={{ width: '100%', height: '80%', display: 'flex', flex: 9 }}>
						{this.isVideo(el.img) ? (

							<VideoCover
								videoOptions={{
									src: el.img,
									ref: videoRef => {
										this.videoRef = videoRef;
									},
									loop: true,
									autoPlay: true,
									muted: true
								}}
							>
							</VideoCover>

						) : (this.isImage(el.img)) ? (
							<img id={`block_${el.i}_image`} style={{ opacity: .4, width: '100%', height: '100%', objectFit: el.background_style }} src={el.img} alt="">
							</img>
						) : (
							<iframe frameBorder="0" style={{ width: "100%", height: "100%" }}
								src={el.img}>
							</iframe>

						)}

					</div>

					{this.state.showId && (
						<div onClick={this.focusBlock.bind(this, el.i)} className={"numberContainer"}>
							<span className={"numberBall"}>
								{`${el.i}`}
							</span>
						</div>
					)}

					{this.state.showName && (
						<div onClick={this.focusBlock.bind(this, el.i)} style={{ width: '100%', position: 'absolute', bottom: 0, background: '#00000021' }}>
							<svg style={{ width: '100%', display: 'flex', height: 'auto', position: 'relative' }} width="100%" height="100%" viewBox="0 0 300 24">
								<text textAnchor="middle" style={{
									textShadow: 'rgb(187, 186, 186) -2px 1px 4px',
									textAnchor: 'inherit',
									color: 'white'

								}} font="18px Helvetica, Arial" stroke="none" fill="#ffffff" textLength='120' lengthAdjust="spacing" x='5' y="18">{`${el.name}`}</text>
							</svg>
						</div>
					)}
					{this.state.showMetric && (
						<div style={{ background: '#0000004f', position: 'absolute', width: '100%', height: '50px', right: '0px', top: 0 }}>
							<span style={{
								fontWeight: 'bold',
								fontSize: 'x-large',

								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								position: 'absolute',
								height: '100%',
								textAlign: 'center',
								color: 'white',
								zIndex: 1,
								left: '10%',
								right: '10%',

								background: 'transparent',
								textShadow: '2px 3px 3px black'
							}} className="text">{el.metric_value} {el.metric_label}</span>
							{el.metric_background && (
								<img style={{ width: '100%', height: '100%', objectFit: el.metric_background_style, position: 'absolute', top: 0 }} src={el.metric_background} alt="">
								</img>
							)}

						</div>
					)}


				</div>

				<div className="top-right-corner-icons initially-hidden">

					<span
						className="edit"
						style={editStyle}
						onClick={this.showDetail.bind(this, el.i)}
					>
						<Edit />
					</span>

					<span
						className="remove"
						style={removeStyle}
						onClick={this.onRemoveItem.bind(this, el.i)}
					>
						<CloseOutlined />
					</span>
				</div>

			</div>

		);
	}






	onAddItem() {
		/*eslint no-console: 0*/
		this.api.addBlock(
			this.boardId,
			{
				name: 'Some Project',
				background: '/paper.jpg',
				metric: {
					name: 'Some Metric',
					label: 'Some Metric Label',
					value: 'Some Value',
					background: '/paper.jpg'
				}
			}
		).then((new_id) => {
			let blocks = [{
				id: new_id
			}]
			for (var item in this.state.items) {
				blocks.push({
					id: this.state.items[item].i
				})
			}
			this.api.updateBoard(this.boardId, { blocks: blocks })
			// console.log("adding", "n" + this.state.newCounter);
			this.setState({
				// Add a new item. It must have a unique key!
				items: this.state.items.concat({
					i: new_id.toString(),
					x: (this.state.items.length * 2) % (this.state.cols || 12),
					y: Infinity, // puts it at the bottom
					w: 2,
					h: 2,
					img: '/paper.jpg',
					background_style: 'cover',
					metric_value: 'Some Value',
					metric_label: 'Metric Label',
					metric_background_style: 'cover',
					metric_background: '/paper.jpg',
					name: 'Some Project',
					color: 'rgb(0, 0, 93)',
					files: []
				}),
				// Increment the counter to ensure key is always unique.
				newCounter: this.state.newCounter + 1
			}, () => {
				this.addVoiceCommands();
			});
		})

	}

	// We're using the cols coming back from this to calculate where to add new items.
	onBreakpointChange(breakpoint, cols) {
		console.log('breakpoint:')
		console.log(breakpoint)
		console.log('cols:')
		console.log(cols)
		this.setState({
			breakpoint: breakpoint,
			cols: cols
		});
	}

	onLayoutChange(layout, layouts) {
		// this.props.onLayoutChange(layout);
		console.log('onLayoutChange')
		console.log('layouts:')
		console.log(layouts)
		console.log('layout:')
		console.log(layout)
		saveToLS("layouts", layouts);
		this.setState({
			layouts: layouts
		});
	}

	onRemoveItem(i) {
		console.log("removing", i);
		this.api.deleteBlock(i).then(() => {
			this.setState({ items: _.reject(this.state.items, { i: i }) }, () => { this.addVoiceCommands(); });
		})
	}

	closeDetail() {
		this.setState({
			showDetail: false,
			blockName: null,
			blockBackground: null,
			blockMetricValue: null,
			blockMetricLabel: null,
			blockMetricBackground: null,
			blockBackgroundStyle: null,
			blockMetricBackgroundStyle: null,

		})
	}

	onNameChange(value) {
		this.setState({
			blockName: value
		});
	}

	onBackgroundStyleChange(value) {
		this.setState({
			blockBackgroundStyle: value
		});
	}

	onMetricBackgroundStyleChange(value) {
		this.setState({
			blockMetricBackgroundStyle: value
		});
	}

	onBackgroundChange(value) {
		this.setState({
			blockBackground: value
		});
	}

	onKeywordsChange(value) {
		this.setState({
			blockKeywords: value
		});
	}

	onContentUrlChange(value) {
		this.setState({
			contentUrl: value
		});
	}

	onMetricKeywordsChange(value) {
		this.setState({
			blockMetricKeywords: value
		});
	}

	onMetricValueChange(value) {
		this.setState({
			blockMetricValue: value
		});
	}

	onMetricBackgroundChange(value) {
		this.setState({
			blockMetricBackground: value
		});
	}

	onMetricLabelChange(value) {
		this.setState({
			blockMetricLabel: value
		});
	}

	updateBlockContent(data) {

		console.log('updateBlockContent:')
		console.log(data)
		this.api.updateBlock(this.state.focus_id, data)

	}

	async generateBackgroundFromContent(e) {
		e.preventDefault();

		let data = {
			url: this.state.contentUrl,
			block_id: this.state.detail_id,
			board_id: this.boardId
		}
		if (this.state.blockKeywords !== null) {
			data['command'] = this.state.blockKeywords
		}
		await this.api.createGif(data).then(
			ret => {
				if (ret) {
					console.log(ret);
				}
			}
		)
	}

	async generateBackgroundFromUrl(e) {
		e.preventDefault();
		let data = {
			url: this.state.contentUrl
		}
		await this.api.createScreenshot(data).then(
			ret => {
				if (ret) {
					let img = ret.url
					this.setState({
						blockBackground: img
					})
				}
			}
		)
	}

	async generateBackground(e) {
		e.preventDefault();
		console.log('here');
		// Initiate gifLoop for set interval
		var refresh;
		// Duration count in seconds
		const duration = 1000 * 10;
		// Giphy API defaults
		const giphy = {
			baseURL: "https://api.giphy.com/v1/gifs/",
			apiKey: "0UTRbFtkMxAplrohufYco5IY74U8hOes",
			tag: "fail",
			// type: "random",
			type: "search",
			rating: "pg-13"
		};
		// Target gif-wrap container
		// const gif_wrap = document.selectElementById("gif_wrap")
		// Giphy API URL
		let giphyURL = encodeURI(
			giphy.baseURL +
			giphy.type +
			"?api_key=" +
			giphy.apiKey +
			"&q=" +
			this.state.blockKeywords +
			"&weirdness=4"
			// "&tag=" +
			// this.state.blockKeywords +
			// "&rating=" +
			// giphy.rating
		);

		// Call Giphy API and render data
		var response = await fetch(giphyURL)
		if (!response.ok) {
			throw Error(response.statusText);
		}
		let data = await response.json()
		// var newGif = data.data.image_original_url
		var b = shuffle(data.data);

		var newGif = b[0].images.original.url
		this.setState({
			blockBackground: newGif
		})
		// var newGif = () => $.getJSON(giphyURL, json => renderGif(json.data));

		// // Display Gif in gif wrap container
		// var renderGif = _giphy => {
		// 	console.log(_giphy);
		// 	// Set gif as bg image
		// 	$gif_wrap.css({
		// 		"background-image": 'url("' + _giphy.image_original_url + '")'
		// 	});
		//
		// 	// Start duration countdown
		// 	// refreshRate();
		// };

		// Call for new gif after duration
		// var refreshRate = () => {
		// 	// Reset set intervals
		// 	clearInterval(refresh);
		// 	refresh = setInterval(function() {
		// 		// Call Giphy API for new gif
		// 		newGif();
		// 	}, duration);
		// };

		// Call Giphy API for new gif
		// newGif();
		//
		//
		// const newGifButton = $('#new-gif');
		//
		// newGifButton.click(newGif)
	}
	scrollUp() {
		var curPos = document.documentElement.scrollTop;
		var els = document.getElementsByClassName("react-grid-item");
		var a = Array.prototype.map.call(els, function (x) { return x.getBoundingClientRect().top });
		a = a.filter(x => x <= curPos)
		if (a.length) {
			var nextPos = Math.min(...a)
			window.scrollTo({
				top: nextPos,
				left: 0,
				behavior: 'smooth'
			});
		}
	}
	scrollDown() {
		var curPos = document.documentElement.scrollTop;
		var els = document.getElementsByClassName("react-grid-item");
		var a = Array.prototype.map.call(els, function (x) { return x.getBoundingClientRect().top });
		a = a.filter(x => x >= curPos)
		if (a.length) {
			var nextPos = Math.min(...a)
			console.log("curPos:", curPos);
			console.log("nextPos:", nextPos);
			window.scrollTo({
				top: nextPos,
				left: 0,
				behavior: 'smooth'
			});
		}


	}
	async generateMetricBackground(e) {
		e.preventDefault();
		console.log('here');
		// Initiate gifLoop for set interval
		var refresh;
		// Duration count in seconds
		const duration = 1000 * 10;
		// Giphy API defaults
		const giphy = {
			baseURL: "https://api.giphy.com/v1/gifs/",
			apiKey: "0UTRbFtkMxAplrohufYco5IY74U8hOes",
			tag: "fail",
			type: "search",
			rating: "pg-13"
		};
		// Target gif-wrap container
		// const gif_wrap = document.selectElementById("gif_wrap")
		// Giphy API URL
		let giphyURL = encodeURI(
			giphy.baseURL +
			giphy.type +
			"?api_key=" +
			giphy.apiKey +
			"&q=" +
			this.state.blockMetricKeywords +
			"&rating=" +
			giphy.rating
		);

		// Call Giphy API and render data
		var response = await fetch(giphyURL)
		if (!response.ok) {
			throw Error(response.statusText);
		}
		let data = await response.json()
		var b = shuffle(data.data);

		var newGif = b[0].images.original.url
		// var newGif = data.data.image_original_url
		this.setState({
			blockMetricBackground: newGif
		})
		// var newGif = () => $.getJSON(giphyURL, json => renderGif(json.data));

		// // Display Gif in gif wrap container
		// var renderGif = _giphy => {
		// 	console.log(_giphy);
		// 	// Set gif as bg image
		// 	$gif_wrap.css({
		// 		"background-image": 'url("' + _giphy.image_original_url + '")'
		// 	});
		//
		// 	// Start duration countdown
		// 	// refreshRate();
		// };

		// Call for new gif after duration
		// var refreshRate = () => {
		// 	// Reset set intervals
		// 	clearInterval(refresh);
		// 	refresh = setInterval(function() {
		// 		// Call Giphy API for new gif
		// 		newGif();
		// 	}, duration);
		// };

		// Call Giphy API for new gif
		// newGif();
		//
		//
		// const newGifButton = $('#new-gif');
		//
		// newGifButton.click(newGif)
	}

	updateBlockDetails(e) {
		e.preventDefault()
		this.api.updateBlock(this.state.detail_id, {
			name: this.state.blockName,
			background: this.state.blockBackground,
			background_style: this.state.blockBackgroundStyle,
			metric: {
				label: this.state.blockMetricLabel,
				value: this.state.blockMetricValue,
				background: this.state.blockMetricBackground,
				background_style: this.state.blockMetricBackgroundStyle
			}
		})
		let updated_items = []
		this.state.items.forEach((item) => {
			if (item.i === this.state.detail_id) {
				item.name = this.state.blockName;
				item.img = this.state.blockBackground;
				item.background_style = this.state.blockBackgroundStyle;
				item.metric_value = this.state.blockMetricValue;
				item.metric_label = this.state.blockMetricLabel;
				item.metric_background = this.state.blockMetricBackground;
				item.metric_background_style = this.state.blockMetricBackgroundStyle;
			}
			updated_items.push(item)
		})
		this.setState({
			items: updated_items
		}, () => {
			this.closeDetail()
		})
	}

	renderHelp() {
		return (
			<div>
				<div className="help">
					<h3>Voice Commands</h3>
					<ul>
						<li>
							<span className="highlight">Go Home</span>
							<span>
								- Navigates to the home page.
							</span>
						</li>
						<li>
							<span className="highlight"><i>[Show/Close]</i> Help</span>
							<span>
								- Shows/hides this help dialog.
							</span>
						</li>

						<li>
							<span className="highlight"><i>[Start/Stop]</i> Slideshow</span>
							<span>
								- Starts/stops the slideshow.
							</span>
						</li>
						<li>
							<span className="highlight">Toggle <i>[Metrics/Names/Ids]</i></span>
							<span>
								- Toggles the display of metrics, names, or ids.
							</span>
						</li>
						<li>
							<span className="highlight"><i>[Save/Reset]</i> Layout</span>
							<span>
								- Saves or resets the layout.
							</span>
						</li>
						<li>
							<span className="highlight">Add Block</span>
							<span>
								- Adds a new block.
							</span>
						</li>
						<li>
							<span className="highlight">No Focus</span>
							<span>
								- Removes focus from any block.
							</span>
						</li>
						<li>
							<span className="highlight">Scroll <i>[Up/Down]</i></span>
							<span>
								- Scrolls up or down to the next block.
							</span>
						</li>
						<li>
							<span className="highlight">Zoom Out</span>
							<span>
								- Zooms out the view.
							</span>
						</li>
						<li>
							<span className="highlight">Reload</span>
							<span>
								- Reloads the page.
							</span>
						</li>
						<li>
							<span className="highlight">Show <i>[Name/Id]</i></span>
							<span>
								- Shows the details of the block with the given name or id.
							</span>
						</li>
						<li>
							<span className="highlight">Edit <i>[Name/Id]</i></span>
							<span>
								- Shows the edit details dialog for the block with the given name or id.
							</span>
						</li>
						<li>
							<span className="highlight">Scroll To <i>[Name/Id]</i></span>
							<span>
								- Scrolls to the block with the given name or id.
							</span>
						</li>
						<li>
							<span className="highlight">Zoom To <i>[Name/Id]</i></span>
							<span>
								- Zooms to the block with the given name or id.
							</span>
						</li>
					</ul>
				</div>
			</div>
		)

	}

	closeHelp() {
		this.setState({
			showHelp: false
		})
	}

	help() {
		this.setState({
			showHelp: true
		})
	}

	renderDetails() {
		console.log('renderDetails!')
		console.log(this.state.items)
		return (

			<div className="detail-group">
				<form onSubmit={this.updateBlockDetails.bind(this)} id="details">
					<div className="form-group">
						<label htmlFor="name">Name</label>
						<input value={this.state.blockName ? this.state.blockName : ""} onChange={e => this.onNameChange(e.target.value)} type="text" className="form-control" id="name" placeholder="Name">
						</input>
					</div>

					<div className="form-group">
						<label htmlFor="background_style">Background Style</label>
						<select value={this.state.blockBackgroundStyle ? this.state.blockBackgroundStyle : ""} onChange={e => this.onBackgroundStyleChange(e.target.value)} className="form-control" id="background_style">
							<option value="cover">cover</option>
							<option value="contain">contain</option>
							<option value="fill">fill</option>
						</select>
						<span id="emailHelp" className="form-text text-muted">{"One of the provided style options."}</span>
					</div>

					<div className="form-group">
						<label htmlFor="background">Background</label>
						<input value={this.state.blockBackground ? this.state.blockBackground : ""} onChange={e => this.onBackgroundChange(e.target.value)} type="text" className="form-control" id="background" placeholder="Background">
						</input>
						<span id="emailHelp" className="form-text text-muted">{"Either an image path or a hosted MP4."}</span>
					</div>
					<div className="form-group">
						<label htmlFor="url">Url</label>
						<input value={this.state.contentUrl ? this.state.contentUrl : ""} onChange={e => this.onContentUrlChange(e.target.value)} type="text" className="form-control" id="contentUrl" placeholder="Url">
						</input>
						<span id="emailHelp" className="form-text text-muted">{"Used when generating backgrounds."}</span>
					</div>

					<div className="form-group">



						<button onClick={this.generateBackgroundFromContent}>Generate New Background From Content</button>
					</div>
					<div className="form-group">



						<button onClick={this.generateBackgroundFromUrl}>Generate New Background From Url</button>
					</div>
					<div className="form-group">
						<label htmlFor="keywords">Keywords</label>
						<input value={this.state.blockKeywords ? this.state.blockKeywords : ""} onChange={e => this.onKeywordsChange(e.target.value)} type="text" className="form-control" id="keywords" placeholder="Keywords">
						</input>
						<span id="emailHelp" className="form-text text-muted">{"Used when generating backgrounds."}</span>
					</div>

					<div className="form-group">


					</div>
					<div className="form-group">
						{this.isVideo(this.state.blockBackground ? this.state.blockBackground : "") ? (

							<VideoCover
								videoOptions={{
									src: this.state.blockBackground,
									ref: videoRef => {
										this.videoRef = videoRef;
									},
									loop: true,
									autoPlay: true,
									muted: true
								}}
							>
							</VideoCover>

						) : (this.isImage(this.state.blockBackground)) ? (
							<img id="backgroundImg" src={this.state.blockBackground ? this.state.blockBackground : ""}></img>
						) : (

							<iframe frameBorder="0" style={{ width: "100%", height: "100%" }}
								src={this.state.blockBackground}>
							</iframe>

						)}
					</div>

					<div className="form-group">



						<button onClick={this.generateBackground}>Generate New Background</button>
					</div>
					<div className="form-group">
						<label htmlFor="metric_value">Metric Value</label>
						<input value={this.state.blockMetricValue ? this.state.blockMetricValue : ""} onChange={e => this.onMetricValueChange(e.target.value)} type="text" className="form-control" id="metric_value" placeholder="Metric Value">
						</input>
					</div>
					<div className="form-group">
						<label htmlFor="metric_label">Metric Label</label>
						<input value={this.state.blockMetricLabel ? this.state.blockMetricLabel : ""} onChange={e => this.onMetricLabelChange(e.target.value)} type="text" className="form-control" id="metric_label" placeholder="Metric Label">
						</input>
					</div>
					<div className="form-group">
						<label htmlFor="metric_background_style">Metric Background Style</label>
						<select value={this.state.blockMetricBackgroundStyle ? this.state.blockMetricBackgroundStyle : ""} onChange={e => this.onMetricBackgroundStyleChange(e.target.value)} className="form-control" id="metric_background_style">
							<option value="cover">cover</option>
							<option value="contain">contain</option>
							<option value="fill">fill</option>
						</select>
						<span id="emailHelp" className="form-text text-muted">{"One of the provided style options."}</span>
					</div>
					<div className="form-group">
						<label htmlFor="metric_background">Metric Background</label>
						<input value={this.state.blockMetricBackground ? this.state.blockMetricBackground : ""} onChange={e => this.onMetricBackgroundChange(e.target.value)} type="text" className="form-control" id="metric_background" placeholder="Metric Background">
						</input>
					</div>
					<div className="form-group">
						<label htmlFor="metric_keywords">Keywords</label>
						<input value={this.state.blockMetricKeywords ? this.state.blockMetricKeywords : ""} onChange={e => this.onMetricKeywordsChange(e.target.value)} type="text" className="form-control" id="metric_keywords" placeholder="Metric Keywords">
						</input>
						<span id="emailHelp" className="form-text text-muted">{"Used when generating metric backgrounds."}</span>
					</div>
					<div className="form-group">

						<img id="backgroundImg" src={this.state.blockMetricBackground ? this.state.blockMetricBackground : ""}></img>
					</div>

					<div className="form-group">



						<button onClick={this.generateMetricBackground}>Generate New Background</button>
					</div>

					<div className="form-check">

					</div>
					<button type="submit" className="btn btn-primary">Submit</button>
				</form>
			</div>

		)
	}

	reload() {
		window.location.reload()
	}


	addVoiceCommands() {
		this.annyang = window.annyang;
		console.log('annyang:')
		console.log(this.annyang)
		if (this.annyang) {
			console.log('yay!')
			// Let's define our first command. First the text we expect, and then the function it should call
			var commands = {
				'go home': function () { window.location.href = '/' },
				'show help': this.help.bind(this),
				'close help': this.closeHelp.bind(this),
				'start slideshow': this.startSlideshow.bind(this),
				'stop slideshow': this.endSlideshow.bind(this),
				'toggle metrics': this.triggerMetricOverlays.bind(this),
				'toggle names': this.triggerNameOverlays.bind(this),
				'toggle ids': this.triggerIdOverlays.bind(this),
				'save layout': this.saveLayout.bind(this),
				'reset layout': this.resetLayout.bind(this),
				'add block': this.onAddItem.bind(this),
				'no focus': this.offFocus.bind(this),
				'scroll up': this.scrollUp.bind(this),
				'scroll down': this.scrollDown.bind(this),
				'zoom out': this.zoomOut.bind(this),
				'reload': this.reload.bind(this)
			};

			for (var i in this.state.items) {
				commands['show ' + this.state.items[i].name] = this.focusBlock.bind(this, this.state.items[i].i);
				commands['edit ' + this.state.items[i].name] = this.showDetail.bind(this, this.state.items[i].i);
				commands['scroll to ' + this.state.items[i].name] = this.scrollToBlock.bind(this, this.state.items[i].i);
				commands['zoom to ' + this.state.items[i].name] = this.zoomIn.bind(this, this.state.items[i].i);
				commands['show ' + this.state.items[i].i] = this.focusBlock.bind(this, this.state.items[i].i);
				commands['edit ' + this.state.items[i].i] = this.showDetail.bind(this, this.state.items[i].i);
				commands['scroll to ' + this.state.items[i].i] = this.scrollToBlock.bind(this, this.state.items[i].i);
				commands['zoom to ' + this.state.items[i].i] = this.zoomIn.bind(this, this.state.items[i].i);
			}

			// Add our commands to annyang
			this.annyang.addCommands(commands);

			this.annyang.addCallback('result', function (phrases) {
				console.log("I think the user said: ", phrases[0]);
				console.log("But then again, it could be any of the following: ", phrases);
			});

			// Start listening. You can call this here, or attach this call to an event, button, etc.
			this.annyang.start();
		}
	}

	componentWillUnmount() {

	}

	async componentDidMount() {
		console.log('ref:')
		console.log(this.gridRef)
		console.log('width:')
		console.log(this.gridRef.state.width)
		document.body.style.backgroundColor = "black";
		this.boardId = this.props.boardId;

		await this.api.getBoard(this.boardId).then(
			ret => {
				if (ret) {
					console.log('ret:')
					console.log(ret)
					let data = ret.blocks
					let layout = ret.layout
					if (this.state.breakpoint in layout) {
						if (layout[this.state.breakpoint].length > 0) {
							if ('showName' in layout[this.state.breakpoint][0]) {
								this.setState({
									showName: layout[this.state.breakpoint][0].showName
								})
							}
							if ('showId' in layout[this.state.breakpoint][0]) {
								this.setState({
									showId: layout[this.state.breakpoint][0].showId
								})
							}
							if ('showMetric' in layout[this.state.breakpoint][0]) {
								this.setState({
									showMetric: layout[this.state.breakpoint][0].showMetric
								})
							}
						}
					}

					this.setState({
						data: data
					}, () => {
						console.log(data)
						let items = []
						console.log('layout:')
						console.log(layout)
						if (layout) {
							console.log('layout is not null')
							console.log(typeof layout)
							originalLayouts = layout
						}
						console.log(originalLayouts)
						console.log('breakpoint in componentDidMount:')
						console.log(this.state.breakpoint)
						for (var i in data) {
							if (originalLayouts[this.state.breakpoint]) {
								console.log('originalLayouts[this.state.breakpoint]:')
								console.log(originalLayouts[this.state.breakpoint])
								console.log('i:')
								console.log(i)
								if (i >= originalLayouts[this.state.breakpoint].length) {
									items.push(
										{
											i: data[i].id.toString(),
											x: (this.state.items.length * 2) % (this.state.cols || 12),
											y: Infinity, // puts it at the bottom
											w: 2,
											h: 2,
											img: data[i].img,
											background_style: data[i].background_style,
											metric_value: data[i].metric_value,
											metric_label: data[i].metric_label,
											metric_background: data[i].metric_background,
											metric_background_style: data[i].metric_background_style,
											name: data[i].name,
											color: data[i].color,
											files: data[i].files,
											checklists: data[i].checklists
										}
									)

								}
								else {
									items.push(
										{
											i: data[i].id.toString(),
											x: originalLayouts[this.state.breakpoint][i].x,
											y: originalLayouts[this.state.breakpoint][i].y,
											w: originalLayouts[this.state.breakpoint][i].w,
											h: originalLayouts[this.state.breakpoint][i].h,
											img: data[i].img,
											background_style: data[i].background_style,
											metric_value: data[i].metric_value,
											metric_label: data[i].metric_label,
											metric_background: data[i].metric_background,
											metric_background_style: data[i].metric_background_style,
											name: data[i].name,
											color: data[i].color,
											files: data[i].files,
											checklists: data[i].checklists
										}
									)
								}

							}
							else {
								items.push(
									{
										i: data[i].id.toString(),
										x: i * 2,
										y: 0,
										w: 2,
										h: 2,
										img: data[i].img,
										background_style: data[i].background_style,
										metric_value: data[i].metric_value,
										metric_label: data[i].metric_label,
										metric_background: data[i].metric_background,
										metric_background_style: data[i].metric_background_style,
										name: data[i].name,
										color: data[i].color,
										files: data[i].files,
										checklists: data[i].checklists
									}
								)
							}
						}
						this.setState({
							items: items
						}, () => {
							originalLayouts = getFromLS("layouts") || {};
							this.setState({ layouts: JSON.parse(JSON.stringify(originalLayouts)) })
							console.log(this.state)
						})
					})
				}
			}
		)
		this.addVoiceCommands();
		this.configureWebsocket();

	}
	// }


	configureWebsocket() {
		this.ws = new WebSocket("wss://qbqljzxguj.execute-api.us-east-1.amazonaws.com/prod");
		console.log('configuring websocket')
		console.log(this.ws);
		var current_this = this;

		this.ws.onopen = function () {
			console.log('topics:')
			console.log(topics)
			current_this.timeout = 250;

			for (var i in topics) {
				console.log("subscribing to:")
				console.log(topics[i]);
				current_this.ws.send(JSON.stringify({
					message: "subscribe",
					topic: topics[i]
				}));
			}
			clearTimeout(current_this.connectInterval);


		};
		this.ws.onerror = err => {
			console.error(
				"Socket encountered error: ",
				err.message,
				"Closing socket"
			);

			current_this.ws.close();
		};
		this.ws.onmessage = function (msg) {
			console.log("message:")
			console.log(msg);
			var data = JSON.parse(msg.data);
			if (data.subject == current_this.boardId.toString()) {
				var event = {
					id: data.id,
					topic: data.topic,
					datetime: data.timestamp,
					message: JSON.parse(data.message),
					subject: data.subject,
					message_attributes: data.message_attributes
				}
				console.log(event)
				current_this.getData(event);
			}
		};
		this.ws.onclose = function (e) {
			console.log(
				`Socket is closed. Reconnect will be attempted in ${Math.min(
					10000 / 1000,
					(current_this.timeout + current_this.timeout) / 1000
				)} second.`,
				e.reason
			);

			current_this.timeout = current_this.timeout + current_this.timeout; //increment retry interval
			current_this.connectInterval = setTimeout(current_this.check, Math.min(10000, current_this.timeout)); //call check function after timeout
		};
	}

	check() {
		if (!this.ws || this.ws.readyState == WebSocket.CLOSED) {
			this.configureWebsocket();
		}
	}

	offFocus() {
		this.setState({
			focus_id: null,
			showFocus: false,
			showDetail: false,
			detail_id: null
		}, () => {
			this.setState({
				isFlipped: false
			})
		})
	}
	// zoomToBlock(id, zoomAmount=1){
	//     var el = document.getElementById(`block_${id}`);
	//     TweenMax.to(`#block_${id}`, 3, {
	//       top: 0,
	//       left: 0,
	//       width: '100%',
	//       height: '100%',
	//       className:"+=fixed",
	//       ease:"smooth"
	//     });
	//     // el.requestFullscreen();
	// }
	// zoomFromBlock(id){
	//     document.exitFullscreen();
	// }
	zoomIn(id) {
		var el = document.getElementById(`block_${id}`);
		this.scrollToBlock(id);
		var that = this;
		this.setState({
			zoomId: id
		}, () => {
			zoom.to({
				element: el,
				callback: function () {
					// zoom.to2({
					//     element: document.body,
					//     element2: el
					// })
					// var el = document.getElementById(`block_${elem.i}`);
					// el.requestFullscreen();
					that.zoomToBlock(id)
					// setTimeout(z=>{
					//     zoom.out();
					// }, 1000);
					// zoom.out();
				}
			});
		})

	}
	zoomOut() {
		var that = this;
		zoom.out({
			callback: function () {
				let id = that.state.zoomId;
				console.log("zoomOut:", that.state.zoomId);
				that.zoomFromBlock(id);
			}
		}

		);


	}
	startSlideshow() {
		console.log('start slideshow');
		console.log(this.slideshowInterval);
		if (this.slideshowInterval == null) {
			clearInterval(this.slideshowInterval);
			this.slideshowInterval = setInterval(x => {
				let elem = this.state.items[Math.floor(Math.random() * this.state.items.length)];
				var el = document.getElementById(`block_${elem.i}`);
				console.log('elem:', elem);
				this.scrollToBlock(elem.i);
				var that = this;
				setTimeout(q => {
					// this.zoomToBlock(elem.i);
					that.zoomIn(elem.i)
					setTimeout(y => {
						// this.zoomOut();
						// document.exitFullscreen();
						// that.zoomOut()
						// zoom.out({
						//     callback: function(){
						//         that.zoomOut()
						//     }
						// }
						//
						// );
						that.zoomOut();
					}, 9500)
				}, 1000);



			}, 15000)
		}
	}
	endSlideshow() {
		if (this.slideshowInterval !== null) {
			clearInterval(this.slideshowInterval);
			this.slideshowInterval = null;
		}

	}
	scrollToBlock(id) {
		// let el = this.state.items.find(x => x.i === id);
		// console.log("scrollToBlock:", el);
		var curPos = document.documentElement.scrollTop;
		let el = document.getElementById(`block_${id}`);
		console.log("curPos:", curPos);
		console.log("nextPos:", el.getBoundingClientRect().top);
		let nextPos = el.getBoundingClientRect().top;
		// window.scrollTo({
		//   top: nextPos,
		//   left: 0,
		//   behavior: 'smooth'
		// });
		el.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
		// zoomToBlock(id);
		// zoomFromBlock(id);
	}
	focusBlock(id) {
		if (this.isDragging || this.isResizing) {
			return
		}
		// console.log('id:')
		// console.log(id)
		// let item = this.state.items.filter(obj => {
		//     return obj.i === id
		// })[0]
		// console.log('item:')
		// console.log(item)
		// if (this.state.blockName === null){
		//     this.onNameChange(item.name)
		// }
		// if (this.state.blockBackground === null){
		//     this.onBackgroundChange(item.img)
		// }
		// if (this.state.blockMetricValue === null){
		//     this.onMetricValueChange(item.metric_value)
		// }
		// if (this.state.blockMetricLabel === null){
		//     this.onMetricLabelChange(item.metric_label)
		// }
		// if (this.state.blockMetricBackground === null){
		//     this.onMetricBackgroundChange(item.metric_background)
		// }
		// console.log(id)
		// console.log('files:')
		let el = this.state.items.find(x => x.i === id)
		console.log("el: ", el);
		// console.log(el.files)
		let files = el.files.map((file) => {
			return {
				...file,
				key: file.key,
				modified: +Moment.utc(file.updated_at),
				size: file.size
			}
		})
		// console.log('setting table data')
		this.setState({
			focus_id: id,
			showFocus: true,
			files: files,
			table_data: el.checklists.map((checklist) => {
				return {
					name: checklist.name,
					created: Moment.utc(checklist.created).fromNow().toString(),
					updated_at: Moment.utc(checklist.updated_at).fromNow().toString(),
					list_items: checklist.list_items,
					id: checklist.id,
					completion: checklist.list_items.filter(x => x.checked === true).length ? ((checklist.list_items.filter(x => x.checked === true).length / checklist.list_items.length * 100).toString() + '%') : '0%'
				}
			})
		}, () => {
			this.setState({
				isFlipped: true
			})
		})

	}

	formatFilesForApi(files) {
		return files.map((file) => {
			return {
				key: file.key,
				id: file.id
			}
		})
	}

	formatChecklistsForApi(checklists) {
		return checklists.map((checklist) => {
			return {
				name: checklist.name,
				id: checklist.id,
				list_items: checklist.list_items
			}
		})
	}

	formatListItemsForApi(listitems) {
		return listitems.map((listitem) => {
			return {
				checked: listitem.checked,
				id: listitem.id,
				value: listitem.value
			}
		})
	}

	async addListItem(listitem, checklist) {
		// console.log('addListItem:')
		// console.log(listitem)
		// console.log('checklist:')
		// console.log(checklist)
		try {
			let new_id = await this.api.add(listitem, 'listitem')
			this.updateChecklist({
				id: checklist.id,
				list_items: checklist.list_items.concat([{
					id: new_id,
					checked: false,
					value: listitem.value
				}])
			})
			return new_id
			// this.api.add(listitem, 'listitem').then((new_id) => {
			//     // const table_data = this.state.table_data;
			//     // const index = table_data.indexOf(checklist);
			//     // table_data[index] = newData;
			//     this.updateChecklist({
			//         id: checklist.id,
			//         list_items: checklist.list_items.concat([{
			//             id: new_id,
			//             checked: false,
			//             value: listitem.value
			//         }])
			//     })
			//     // this.setState({
			//     //     table_data: this.state.table_data.concat([{
			//     //         name: checklist.name,
			//     //         id: new_id,
			//     //         list_items: [],
			//     //         created: Moment.utc().fromNow().toString(),
			//     //         updated_at: Moment.utc().fromNow().toString(),
			//     //         completion: '0%'
			//     //
			//     //     }])
			//     // }, () => {
			//     //     this.updateBlockContent({
			//     //         checklists: this.formatChecklistsForApi(this.state.table_data)
			//     //     })
			//     // } )
			//     return new_id
			//
			// })
		}
		catch (error) {
			console.log(error);
		}

	}
	updateListItem(listitem, checklist) {
		// console.log('updateListItem:')
		// console.log(listitem)
		this.api.update(listitem.id, listitem, 'listitem')
		const table_data = this.state.table_data;
		const index = table_data.indexOf(checklist);
		const list_items = table_data[index].list_items;
		const list_item_index = list_items.indexOf(list_items.find(x => x.id === listitem.id));
		list_items[list_item_index] = listitem

		table_data[index].list_items = list_items;
		// console.log(this.state.table_data)
		// console.log(table_data)
		this.setState({ table_data }, () => {

		});
		// this.updateChecklist({
		//     id: checklist.id,
		//     list_items: checklist.list_items.concat([{
		//         id: listitem.id,
		//         checked: listitem.checked,
		//         value: listitem.value
		//     }])
		// })
	}
	deleteListItem(listitem,) {
		// console.log('deleteListItem:')
		// console.log(listitem)
		this.api.delete(listitem.id, 'listitem')
		// this.updateChecklist({
		//     id: checklist.id,
		//     list_items: checklist.list_items.concat([{
		//         id: listitem.id,
		//         checked: listitem.checked,
		//         value: listitem.value
		//     }])
		// })
	}


	addChecklist(checklist) {
		// console.log('addChecklist:')
		// console.log(checklist)
		this.api.add(checklist, 'checklist').then((new_id) => {
			this.setState({
				table_data: this.state.table_data.concat([{
					name: checklist.name,
					id: new_id,
					list_items: [],
					created: Moment.utc().fromNow().toString(),
					updated_at: Moment.utc().fromNow().toString(),
					completion: '0%'

				}])
			}, () => {
				this.updateBlockContent({
					checklists: this.formatChecklistsForApi(this.state.table_data)
				})
			})

		})
	}
	updateChecklist(checklist) {
		// console.log('updateChecklist:')
		// console.log(checklist)
		this.api.update(checklist.id, checklist, 'checklist')
	}
	deleteChecklist(checklist) {
		// console.log('deleteChecklist:')
		// console.log(checklist)
		this.api.delete(checklist.id, 'checklist')
	}

	addFile(file) {
		// console.log('addFile:')
		// console.log(file)
		this.api.add(file, 'file').then((new_id) => {
			this.setState({
				files: this.state.files.concat([{
					key: file.key,
					id: new_id
				}])
			}, () => {
				this.updateBlockContent({
					files: this.formatFilesForApi(this.state.files)
				})
			})

		})
	}

	updateFile(file) {
		// console.log('updateFile:')
		// console.log(file)
		this.api.update(file.id, file, 'file')
	}

	deleteFile(file) {
		// console.log('deleteFile:')
		// console.log(file)
		this.api.delete(file.id, 'file')
	}

	handleCreateFolder = (key) => {
		// console.log('handlecreatefolder')
		// console.log(key)
		this.addFile({
			key: key
		})

		// this.setState(state => {
		//   state.files = state.files.concat([{
		//     key: key,
		//   }])
		//   return state
		// })
	}
	handleCreateFiles = (files, prefix) => {
		const newFiles = files.map((file) => {
			let newKey = prefix
			if (prefix !== '' && prefix.substring(prefix.length - 1, prefix.length) !== '/') {
				newKey += '/'
			}
			newKey += file.name
			return {
				key: newKey,
				size: file.size,
				modified: +Moment(),
			}
		})
		const uniqueNewFiles = []
		newFiles.map((newFile) => {
			let exists = false
			this.state.files.map((existingFile) => {
				if (existingFile.key === newFile.key) {
					exists = true
				}
			})
			if (!exists) {
				uniqueNewFiles.push(newFile)
				this.addFile({
					key: newFile.key
				})
			}
		})

		// this.setState({
		//     files: this.state.files.concat(uniqueNewFiles)
		// }, () => {
		//     this.updateBlockContent({
		//         files: this.formatFilesForApi(this.state.files)
		//     })
		// })
		// this.setState(state => {
		// const newFiles = files.map((file) => {
		//   let newKey = prefix
		//   if (prefix !== '' && prefix.substring(prefix.length - 1, prefix.length) !== '/') {
		//     newKey += '/'
		//   }
		//   newKey += file.name
		//   return {
		//     key: newKey,
		//     size: file.size,
		//     modified: +Moment(),
		//   }
		// })

		// const uniqueNewFiles = []
		// newFiles.map((newFile) => {
		//   let exists = false
		//   state.files.map((existingFile) => {
		//     if (existingFile.key === newFile.key) {
		//       exists = true
		//     }
		//   })
		//   if (!exists) {
		//     uniqueNewFiles.push(newFile)
		//   }
		// })
		// state.files = state.files.concat(uniqueNewFiles)
		// return state
		// })
	}
	handleRenameFolder = (oldKey, newKey) => {
		const newFiles = []
		this.state.files.map((file) => {
			if (file.key.substr(0, oldKey.length) === oldKey) {
				newFiles.push({
					...file,
					key: file.key.replace(oldKey, newKey),
					modified: +Moment(),
				})
				// console.log('here!')
				// console.log(file)
				this.updateFile({
					id: file.id,
					key: file.key.replace(oldKey, newKey)
				})
			} else {
				newFiles.push(file)
				// this.updateFile({
				//     id: file.id,
				//     key: file.key
				// })

			}
		})
		this.setState({
			files: newFiles
		}, () => {
			this.updateBlockContent({
				files: this.formatFilesForApi(this.state.files)
			})
		})

	}
	handleRenameFile = (oldKey, newKey) => {
		const newFiles = []
		this.state.files.map((file) => {
			if (file.key === oldKey) {
				newFiles.push({
					...file,
					key: newKey,
					modified: +Moment(),
				})
				this.updateFile({
					id: file.id,
					key: newKey
				})
			} else {
				newFiles.push(file)
				this.updateFile({
					id: file.id,
					key: file.key
				})
			}
		})
		this.setState({
			files: newFiles
		}, () => {
			this.updateBlockContent({
				files: this.formatFilesForApi(this.state.files)
			})
		})
		// this.setState(state => {
		//   const newFiles = []
		//   state.files.map((file) => {
		//     if (file.key === oldKey) {
		//       newFiles.push({
		//         ...file,
		//         key: newKey,
		//         modified: +Moment(),
		//       })
		//     } else {
		//       newFiles.push(file)
		//     }
		//   })
		//   state.files = newFiles
		//   return state
		// })
	}
	handleDeleteFolder = (folderKey) => {
		const newFiles = []
		this.state.files.map((file) => {
			if (file.key.substr(0, folderKey.length) !== folderKey) {
				newFiles.push(file)
			}
			else {
				this.deleteFile(file)
			}
		})
		this.setState({
			files: newFiles
		}, () => {
			this.updateBlockContent({
				files: this.formatFilesForApi(this.state.files)
			})
		})
		// this.setState(state => {
		//   const newFiles = []
		//   state.files.map((file) => {
		//     if (file.key.substr(0, folderKey.length) !== folderKey) {
		//       newFiles.push(file)
		//     }
		//   })
		//   state.files = newFiles
		//   return state
		// })
	}
	handleDeleteFile = (fileKey) => {
		const newFiles = []
		this.state.files.map((file) => {
			if (file.key !== fileKey) {
				newFiles.push(file)
			}
			else {
				this.deleteFile(file)
			}
		})
		this.setState({
			files: newFiles
		}, () => {
			this.updateBlockContent({
				files: this.formatFilesForApi(this.state.files)
			})
		})
		// this.setState(state => {
		//   const newFiles = []
		//   state.files.map((file) => {
		//     if (file.key !== fileKey) {
		//       newFiles.push(file)
		//     }
		//   })
		//   state.files = newFiles
		//   return state
		// })
	}

	render() {
		// console.log('render')

		return (
			<div>
				{this.state.showHelp && (
					<div className="help-div">
						<div className="detail-form">
							<div className="top-right-corner-icons">
								<span
									className="remove"
									style={removeStyle2}
									onClick={
										this.closeHelp.bind(this)
									}
								>
									<CloseOutlined />
								</span>
							</div>
							{this.renderHelp()}
						</div>
					</div>
				)}
				{this.state.showDetail && (
					<div className="detail-div">
						<div className="detail-form">
							<div className="top-left-corner-icons">
								<span
									className="remove"
									style={removeStyle3}
									onClick={
										this.closeDetail.bind(this)
									}
								>
									<CloseOutlined />
								</span>
							</div>
							{this.renderDetails()}
						</div>
					</div>
				)
				}
				{this.state.focus_id && (
					<div className="focus-div">
						<div className="detail-form">
							<div className="top-right-corner-icons">
								<span
									className="remove"
									style={removeStyle2}
									onClick={
										this.offFocus.bind(this)
									}
								>
									<CloseOutlined />
								</span>
							</div>

						</div>
					</div>
				)
				}

				<ReactCardFlip isFlipped={this.state.isFlipped} flipDirection="vertical">

					<ResponsiveReactGridLayout
						key="front"
						ref={gridRef => {
							this.gridRef = gridRef;
						}}
						className="layout"
						cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
						breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
						rowHeight={100}
						layouts={this.state.layouts}
						onLayoutChange={(layout, layouts) =>
							this.onLayoutChange(layout, layouts)
						}
						onBreakpointChange={this.onBreakpointChange}
						{...this.props}
						onDrag={this.onDrag}
						onDragStop={this.onDragStop}
						onResizeStart={this.onResizeStart}
						onResizeStop={this.onResizeStop}
					>

						{_.map(this.state.items, el => this.createElement(el))}
					</ResponsiveReactGridLayout>
					<div key="back" className="layout">
						{this.state.focus_id && this.showElement()}
					</div>

				</ReactCardFlip>
				{(!this.state.focus_id || this.state.showActionButtons) && (
					<div id="actionButtons" className="buttonHolder">
						<button onClick={this.onAddItem}>Add Block</button>
						<button onClick={() => this.resetLayout()}>Reset Layout</button>
						<button onClick={() => this.saveLayout()}>Save Layout</button>
						<button onClick={() => this.triggerNameOverlays()}>Toggle Name Overlays</button>
						<button onClick={() => this.triggerMetricOverlays()}>Toggle Metric Overlays</button>
						<button onClick={() => this.triggerIdOverlays()}>Toggle Id Overlays</button>
						<button onClick={() => this.zoomOut()}>Zoom Out</button>
						<button onClick={() => this.startSlideshow()}>Start Slideshow</button>
						<button onClick={() => this.endSlideshow()}>End Slideshow</button>
						<div>
							<input value={this.state.commandBoxText ? this.state.commandBoxText : ""} onChange={e => this.setState({ commandBoxText: e.target.value })} type="text" id="commandBox" autoFocus ></input>
							<button onClick={() => this.submitCommand()}>Submit Command</button>
						</div>
						<span style={{ color: 'white', position: 'absolute', right: '5px', bottom: 0, fontSize: 'xx-large' }}>{'+'}</span>
					</div>
				)}
				{this.state.showActionButtons && (
					<div id="commandContainer" className="commandContainer">

						<input ref={(input) => { this.nameInput = input; }} placeholder="Enter Command" onKeyPress={event => { if (event.key === 'Enter') { this.submitCommand() } }} value={this.state.commandBoxText ? this.state.commandBoxText : ""} onChange={e => this.setState({ commandBoxText: e.target.value })} type="text" id="commandBox"></input>
						<button onClick={() => this.submitCommand()}>Submit Command</button>

					</div>
				)}

			</div>
		);
	}
}


export default BoardView;


function getFromLS(key) {
	let ls = {};
	if (global.localStorage) {
		try {
			ls = JSON.parse(global.localStorage.getItem("rgl-8")) || {};
		} catch (e) {
			/*Ignore*/
		}
	}
	return ls[key];
}

function saveToLS(key, value) {
	if (global.localStorage) {
		global.localStorage.setItem(
			"rgl-8",
			JSON.stringify({
				[key]: value
			})
		);
	}
}

// if (require.main === module) {
//   require("./test-hook.jsx")(module.exports);
// }
// export default BoardView;
