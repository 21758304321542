import React, { Component } from 'react';
import Api from './Api';


class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			boards: []
		}
		this.api = new Api()
		this.renderBoards = this.renderBoards.bind(this)
	}
	componentDidMount() {
		this.fetchBoards();
	}
	async fetchBoards() {
		await this.api.getBoards().then(
			ret => {
				if (ret) {
					let boards = ret.boards;
					this.setState({
						boards: boards
					})
				}
			}
		)
	}
	renderBoards() {
		console.log('boards:', this.state.boards);
		const boards = this.state.boards.map((board) =>
			<div key={board.id} className="boardEntry">
				<a href={`/board/${board.id}`}><h1>{board.name}</h1></a>
				<a className='highlight' href={`/board/${board.id}/remote`}><i>Remote</i></a>
			</div>
		);
		return (
			<div className="boardsList">
				{boards}
			</div>
		)

	}
	render() {
		return (
			<div>
				<div className="commandContainer2">
					<div className="homeBox">
						<h1>Boards</h1>
						<hr />
						{this.renderBoards()}
						<hr />
						<div className="createBoard">
							<a href={"/board/create"}>
								<h1 style={{ color: "green" }}>Create Board +</h1>
							</a>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Home
