import React from "react";


class Api extends React.PureComponent {
	async publishMessage(data) {
		try {
			let response = await fetch(
				`https://zzmkyf9ff9.execute-api.us-east-1.amazonaws.com/dev/v1/publish`,
				{
					method: "POST",
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json"
					},
					body: JSON.stringify(data)
				}
			)
			if (!response.ok) {
				throw Error(response.statusText);
			}
			let response_data = await response.json()
			// console.log(response_data)
			// console.log(kind + ' Added')
			return response_data
		} catch (error) {
			console.log(error);
		}
	}
	async getBoards() {
		try {
			let response = await fetch(
				`https://progrzn-pro-f3031fa4bc8b.herokuapp.com/api/v1/board/?username=admin&api_key=test`
			)
			if (!response.ok) {
				throw Error(response.statusText);
			}
			let data = await response.json()
			console.log(data)

			return { boards: data.objects }
		} catch (error) {
			console.log(error);
		}

	}
	async getBoard(id) {
		try {
			let response = await fetch(
				`https://progrzn-pro-f3031fa4bc8b.herokuapp.com/api/v1/board/${id}/?username=admin&api_key=test`
			)
			if (!response.ok) {
				throw Error(response.statusText);
			}
			let data = await response.json()
			let layout = data.layout
			let blocks = []
			for (var b in data.blocks) {
				let block = data.blocks[b]
				blocks.push(
					{
						id: block.id,
						img: block.background,
						background_style: block.background_style,
						metric_value: block.metric.value,
						metric_label: block.metric.label,
						metric_background: block.metric.background,
						metric_background_style: block.metric.background_style,
						name: block.name,
						color: 'rgb(32, 80, 93)',
						files: block.files,
						checklists: block.checklists
					}
				)
			}
			console.log(layout)
			return { blocks: blocks, layout: layout }
		} catch (error) {
			console.log(error);
		}

	}

	async update(id, data, kind) {
		try {
			let response = await fetch(
				`https://progrzn-pro-f3031fa4bc8b.herokuapp.com/api/v1/${kind}/${id}/?username=admin&api_key=test`,
				{
					method: "PATCH",
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json"
					},
					body: JSON.stringify(data)
				}
			)
			if (!response.ok) {
				throw Error(response.statusText);
			}
			console.log(response)
			console.log(kind + ' updated!')
		} catch (error) {
			console.log(error);
		}
	}

	async add(data, kind) {

		try {
			let response = await fetch(
				`https://progrzn-pro-f3031fa4bc8b.herokuapp.com/api/v1/${kind}/?username=admin&api_key=test`,
				{
					method: "POST",
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json"
					},
					body: JSON.stringify(data)
				}
			)
			if (!response.ok) {
				throw Error(response.statusText);
			}
			let response_data = await response.json()
			console.log(response_data)
			console.log(kind + ' Added')
			return response_data.id
		} catch (error) {
			console.log(error);
		}

	}

	async createGif(data) {

		try {
			let response = await fetch(
				`https://8y2kbobeqc.execute-api.us-east-1.amazonaws.com/dev/v1/makegif`,
				{
					method: "POST",
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json"
					},
					body: JSON.stringify(data)
				}
			)
			if (!response.ok) {
				throw Error(response.statusText);
			}
			let response_data = await response.json()
			// console.log(response_data)
			// console.log(kind + ' Added')
			return response_data
		} catch (error) {
			console.log(error);
		}

	}

	async createScreenshot(data) {

		try {
			let response = await fetch(
				`https://progrzn-pro-f3031fa4bc8b.herokuapp.com/screenshot/?username=admin&api_key=test`,
				{
					method: "POST",
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json"
					},
					body: JSON.stringify(data)
				}
			)
			if (!response.ok) {
				throw Error(response.statusText);
			}
			let response_data = await response.json()
			// console.log(response_data)
			// console.log(kind + ' Added')
			return response_data
		} catch (error) {
			console.log(error);
		}

	}

	async delete(id, kind) {
		try {
			let response = await fetch(
				`https://progrzn-pro-f3031fa4bc8b.herokuapp.com/api/v1/${kind}/${id}/?username=admin&api_key=test`,
				{
					method: "DELETE",
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json"
					}
				}
			)
			if (!response.ok) {
				throw Error(response.statusText);
			}
			console.log(response)
			console.log(kind + ' deleted!')
		} catch (error) {
			console.log(error);
		}

	}

	async updateBoard(id, data) {
		console.log('updateBoard data:')
		console.log(data)
		try {
			let response = await fetch(
				`https://progrzn-pro-f3031fa4bc8b.herokuapp.com/api/v1/board/${id}/?username=admin&api_key=test`,
				{
					method: "PATCH",
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json"
					},
					body: JSON.stringify(data)
				}
			)
			if (!response.ok) {
				throw Error(response.statusText);
			}
			console.log(response)
			console.log('Board updated!')
		} catch (error) {
			console.log(error);
		}

	}

	async updateBlock(id, data) {
		console.log('updateBlock data:')
		console.log(data)
		try {
			let response = await fetch(
				`https://progrzn-pro-f3031fa4bc8b.herokuapp.com/api/v1/block/${id}/?username=admin&api_key=test`,
				{
					method: "PATCH",
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json"
					},
					body: JSON.stringify(data)
				}
			)
			if (!response.ok) {
				throw Error(response.statusText);
			}
			console.log(response)
			console.log('Block updated!')
			return
		} catch (error) {
			console.log(error);
		}

	}

	async addBlock(board_id, data) {
		console.log('addBlock data:')
		console.log(data)
		try {
			let response = await fetch(
				`https://progrzn-pro-f3031fa4bc8b.herokuapp.com/api/v1/block/?username=admin&api_key=test`,
				{
					method: "POST",
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json"
					},
					body: JSON.stringify(data)
				}
			)
			if (!response.ok) {
				throw Error(response.statusText);
			}
			let response_data = await response.json()
			console.log(response_data)
			console.log('Block Added')
			return response_data.id
		} catch (error) {
			console.log(error);
		}

	}

	async deleteBlock(block_id) {
		try {
			let response = await fetch(
				`https://progrzn-pro-f3031fa4bc8b.herokuapp.com/api/v1/block/${block_id}/?username=admin&api_key=test`,
				{
					method: "DELETE",
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json"
					}
				}
			)
			if (!response.ok) {
				throw Error(response.statusText);
			}
			console.log(response)
			console.log('Block deleted!')
		} catch (error) {
			console.log(error);
		}

	}
}

export default Api
