import React, {Component} from 'react';
import Api from './Api';


class CreateBoard extends Component {
    constructor(props){
        super(props);
        this.state = {
            commandBoxText: ""
        }
        this.createBoard = this.createBoard.bind(this)
        this.api = new Api()
    }
    componentDidMount(){

    }
    async createBoard(){
        // this.state.commandBoxText
        let data = {
            name: this.state.commandBoxText,
            user: {
                id: 1
            }
        }
        await this.api.add(data, "board").then(
            ret => {
                if (ret){
                    let boardId = ret;
                    window.location.href = `/board/${boardId}`
                }
            }
        )
    }
    render(){
        return (
            <div>
                <div class="commandContainer">
                <div class="createBox">
                <input ref={(input) => { this.nameInput = input; }}  placeholder="Enter Board Name" onKeyPress={event => {if (event.key === 'Enter') {this.createBoard()}}} value={this.state.commandBoxText ? this.state.commandBoxText : ""} onChange={e => this.setState({commandBoxText: e.target.value})} type="text" id="createBox"></input>
                <button onClick={() => this.createBoard()}>Create Board</button>
                </div>
                </div>
            </div>
        );
    }
}

export default CreateBoard
